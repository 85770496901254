import { getLabels } from '@/lib/adapters/label-adapter';
import { getLocations } from '@/lib/adapters/users-adapter';
import { Accent, CmsTooltip, CustomButton } from '@/lib/components';
import { IdentifierType, LocationProps } from '@/lib/types';
import { Relation } from '@/lib/types/user';
import i18n, { countries } from '@/lib/utils/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import {
  Col,
  Form,
  FormItemProps,
  Input,
  Modal,
  Row,
  Select,
  Switch,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicLabelForm } from './dynamicLabelForm';
import { MailboxInput } from './mailboxInput';
import { RelationDeleteModal } from './relationDeleteModal';

export interface EditModalProps {
  visible: boolean;
  loading: boolean;
  relation?: Relation;
  identifierTypes?: IdentifierType[];
  onRelationFormClose?: () => void;
  onRelationFormSuccess?: (values: Relation) => void;
  onRelationDelete?: () => void;
}

const IdentifierLayout: FormItemProps = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  colon: false,
};
const DEFAULT_LAYOUT: FormItemProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  style: { marginBottom: '2px' },
};
const ACCENT_STYLE = {
  marginBottom: '4px',
};

const getCountryList = (lngCode: string) => {
  let list = countries.getNames(lngCode);
  // If the list is empty fallback to EN
  if (!list || !Object.keys(list).length) {
    list = countries.getNames('EN');
  }

  return list;
};

export const EditRelation: FC<EditModalProps> = ({
  visible,
  loading,
  relation,
  identifierTypes,
  onRelationFormClose,
  onRelationFormSuccess,
  onRelationDelete,
}) => {
  const onFormFinish = (values: Relation) => {
    onRelationFormSuccess?.(values);
  };

  return (
    <Modal
      open={visible}
      onCancel={onRelationFormClose}
      title="Relatie wijzigen"
      destroyOnClose
      footer={false}
      width={700}
      getContainer={false}
    >
      <EditForm
        relation={relation}
        identifierTypes={identifierTypes}
        onFormFinish={onFormFinish}
        loading={loading}
        onRelationDelete={onRelationDelete}
      />
    </Modal>
  );
};
const EditForm = ({
  relation,
  identifierTypes,
  onFormFinish,
  loading,
  onRelationDelete,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [locations, setLocations] = useState<LocationProps[]>([]);

  const { isLoading, data: labels } = useQuery({
    queryKey: ['labels'],
    queryFn: () => getLabels(),
    retry: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      const locations = await getLocations();
      setLocations(locations);
    };
    fetchData();
  }, []);

  const countryList = getCountryList(i18n.language.substring(0, 2));
  return (
    <>
      <Form
        name="editRelation"
        initialValues={{ ...relation }}
        form={form}
        onFinish={(values) => onFormFinish({ ...relation, ...values })}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...DEFAULT_LAYOUT}
              name="name"
              label={t('relation.name')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...DEFAULT_LAYOUT}
              name="zipcode"
              label={t('relation.zipcode')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...DEFAULT_LAYOUT}
              name="street"
              label={t('relation.street')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...DEFAULT_LAYOUT}
              name="houseNumber"
              label={t('relation.housenumber')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...DEFAULT_LAYOUT}
              name="city"
              label={t('relation.city')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...DEFAULT_LAYOUT}
              name="country"
              label={t('relation.country')}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.entries(countryList)
                  .sort((a, b) => a[1].localeCompare(b[1]))
                  .map(([key, value]) => (
                    <Select.Option
                      key={key}
                      value={key}
                      title={Array.isArray(value) ? value.join(', ') : value}
                    >
                      {Array.isArray(value) ? value.join(', ') : value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              {...DEFAULT_LAYOUT}
              name="mailbox"
              label={t('relation.mailbox')}
            >
              <MailboxInput />
            </Form.Item>
            <Form.List name="safeEmailAddresses">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Accent type="div" color="default" style={ACCENT_STYLE}>
                    {t('relation.safeEmailAddress')}
                  </Accent>
                  {fields.map((field, index) => (
                    <>
                      <Form.Item {...field}>
                        <Input
                          type="email"
                          autoComplete="new-email"
                          addonAfter={
                            <FontAwesomeIcon
                              onClick={() => remove(field.name)}
                              icon={['far', 'trash-alt']}
                              size="sm"
                            />
                          }
                        />
                      </Form.Item>
                    </>
                  ))}
                  <CustomButton color="secondary" ghost onClick={() => add()}>
                    {t('relation.AddsafeEmailAddress')}
                  </CustomButton>
                </>
              )}
            </Form.List>
            <Form.Item
              {...DEFAULT_LAYOUT}
              label={t('relation.theme')}
              name="theme"
            >
              <Select>
                <Select.Option key="agrinota" value="agrinota">
                  Agrinota
                </Select.Option>
                <Select.Option key="mijnalfa" value="mijnalfa">
                  MijnAlfa
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              {...DEFAULT_LAYOUT}
              label={t('relation.location')}
              name="locationId"
              rules={[]}
            >
              <Select
                showSearch
                optionFilterProp="label"
                options={locations
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((location) => ({
                    label: location.name,
                    value: location.id,
                  }))}
              />
            </Form.Item>
            <Form.Item
              label={
                <CmsTooltip toolTipKey="relation.blockBulkNotifications">
                  {t('relation.blockBulkNotifications')}
                </CmsTooltip>
              }
              name="blockBulkNotifications"
              valuePropName="checked"
              labelAlign="left"
              labelCol={{ span: 12 }}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label={
                <CmsTooltip toolTipKey="relation.isBlocked">
                  {t('relation.isBlocked')}
                </CmsTooltip>
              }
              name="isBlocked"
              valuePropName="checked"
              labelAlign="left"
              labelCol={{ span: 12 }}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label={
                <CmsTooltip toolTipKey="relation.isFrozen">
                  {t('relation.isFrozen')}
                </CmsTooltip>
              }
              name="isFrozen"
              valuePropName="checked"
              labelAlign="left"
              labelCol={{ span: 12 }}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.List name="identifiers">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Accent type="div" color="primary">
                    Identifiers
                  </Accent>
                  {fields.map((field, index) => (
                    <Row key={index}>
                      <Col>
                        <Form.Item
                          {...IdentifierLayout}
                          {...field}
                          label={t('relation.identifier.identifier')}
                          name={[field.name, 'identifier']}
                          key={[field.key, 'identifier'].join('_')}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...IdentifierLayout}
                          {...field}
                          label={t('relation.identifier.type')}
                          name={[field.name, 'identifierType']}
                          key={[field.key, 'identifierType'].join('_')}
                        >
                          <Select
                            showSearch
                            optionFilterProp="label"
                            options={identifierTypes.map((type) => ({
                              label: type.type,
                              value: type.id,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item {...field}>
                          <CustomButton
                            type="link"
                            danger
                            shape="circle"
                            size="small"
                            onClick={() => remove(field.name)}
                            icon={<FontAwesomeIcon icon="trash-alt" />}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <CustomButton
                    color="secondary"
                    ghost
                    onClick={() => add()}
                    icon
                  >
                    Identifier toevoegen
                  </CustomButton>
                </>
              )}
            </Form.List>
            <Row>
              <Accent type="div" color="primary">
                Labels
              </Accent>
            </Row>
            {labels && (
              <Form.Item name="labels">
                {labels
                  .filter((x) => x.valueCount)
                  .map((x) => (
                    <DynamicLabelForm key={x.id} label={x} />
                  ))}
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <CustomButton
              type="ghost"
              danger
              icon={<FontAwesomeIcon icon="trash-alt" />}
              onClick={() => setIsDeleteModalVisible(true)}
              loading={loading}
            >
              {t('general.actions.delete')}
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              htmlType="submit"
              type="primary"
              color="secondary"
              loading={loading}
            >
              {t('general.actions.save')}
            </CustomButton>
          </Col>
        </Row>
      </Form>
      <RelationDeleteModal
        relation={relation}
        isModalVisible={isDeleteModalVisible}
        setIsModalVisible={setIsDeleteModalVisible}
        onSubmitHandler={onRelationDelete}
      />
    </>
  );
};