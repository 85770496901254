import {
  BatchRejectRequest,
  BatchReprocessRequest,
  ImportInvoice,
  IncomingFile,
  Invoice,
  InvoiceDetails,
  InvoiceFilter,
  InvoicePatchRequest,
  InvoiceType,
  InvoiceUpdateRequest,
  InvoiceWithouthRelation,
  OtherDocument,
  PagedResponse,
  ProcessFile,
  ProcessFileFilter,
  SortOrder,
} from '@/lib/types';
import {
  Relation,
  RelationIdentifier,
} from '@/lib/types/user';
import { api } from '@/lib/utils/api.axios';
import { createFilterParams } from '@/lib/utils/helpers';
import { apiBasePaths } from '../queries';

const BASE_URL = `${apiBasePaths.invoices}/v1`;
const BASE_URL_V2 = `${apiBasePaths.invoices}/v2`;

export const createInvoice = async (file: Blob, type: 'purchase' | 'sales') => {
  let form = new FormData();
  form.append('file', file);
  form.append('uploadType', type);

  return api.post<IncomingFile>(`${BASE_URL}/incomingfiles`, form).then((r) => {
    return r.data;
  });
};

export const createDocument = async (
  file: Blob,
  name?: string,
  year?: number,
) => {
  let form = new FormData();
  form.append('file', file);
  if (name) form.append('name', name);
  if (year) form.append('year', year.toString());
  return api.post<IncomingFile>(`${BASE_URL}/documents`, form).then((r) => {
    return r.data;
  });
};

export const getDocument = (id: number | string) =>
  api.get<OtherDocument>(`${BASE_URL}/documents/${id}`).then((r) => r.data);

export const getInvoices = (filter?: InvoiceFilter) =>
  api
    .get<PagedResponse<Invoice[]>>(`${BASE_URL_V2}/invoices`, {
      params: createFilterParams(filter),
    })
    .then((r) => r.data);

export const resendInvoice = (id: number, switchChannel: boolean) =>
  api
    .patch<Invoice>(
      `${BASE_URL}/invoices/${id}/resend?switchChannel=${switchChannel}&deleteInvoice=true`,
    )
    .then((r) => r.data);

export const switchInvoiceType = (id: number, type: InvoiceType) =>
  api
    .patch<Invoice>(
      `${BASE_URL}/invoices/${id}`, { type }
    )
    .then(r => r.data);

export const getLatestInvoice = (filter?: InvoiceFilter) =>
  getInvoices({
    ...filter,
    order: SortOrder.Descending,
    page: 1,
    pageSize: 1,
  }).then((invoices) => invoices?.data?.[0]);

// INVOICES
export const getUnhandledInvoices = () =>
  getInvoices({
    isHandled: false,
    orderField: 'invoiceDate',
    order: SortOrder.Descending,
  });

export const getInvoiceDetails = (id: string | number) =>
  api.get<InvoiceDetails>(`${BASE_URL}/invoices/${id}`).then((r) => r.data);

export const deleteInvoice = (
  id: string | number,
  deleteLinkedFiles: boolean,
) =>
  api
    .delete<Invoice>(
      `${BASE_URL}/invoices/${id}?deleteLinkedFiles=${deleteLinkedFiles}`,
    )
    .then((r) => r.data);

// NOTES
export const postNote = (id: number, message: string) =>
  api
    .post(`${BASE_URL}/invoices/${id}/note`, { message: message })
    .then((r) => r.data);

// ARCHIVE
export const setArchived = (id: number, isArchived: boolean) =>
  api
    .patch(`${BASE_URL}/invoices/${id}`, {
      isArchived,
      isHandled: true,
    })
    .then((r) => r.data);

// HANDLE
export const setIsHandled = (id: number, isHandled: boolean) =>
  api.patch(`${BASE_URL}/invoices/${id}`, { isHandled }).then((r) => r.data);

export const setAutoHandle = (id: number) =>
  api.post(`${BASE_URL}/invoices/${id}/isautohandler`).then((r) => r.data);

export const removeAutoHandle = (id: number) =>
  api.delete(`${BASE_URL}/invoices/${id}/isautohandler`).then((r) => r.data);

export const setAutoPayment = (id: number, enabled: boolean) =>
  api
    .patch(`${BASE_URL}/invoices/${id}/isautopayment`, `${enabled}`, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((r) => r.data);

// ISINPAYMENT
export const setIsInPayment = (id: number, value: boolean) => {
  const request: InvoicePatchRequest = {
    isInPayment: value,
  };

  if (value) {
    // Payments set to payment are considered handled too.
    request.isHandled = true;
  }

  return api.patch(`${BASE_URL}/invoices/${id}`, request).then((r) => r.data);
};

export const setIsInPaymentBatch = (ids: number[], value: boolean) => {
  const queryString = [
    ...ids.map(id => `ids=${id}`),
    `isInPayment=${value}`
  ].join('&');

  return api
    .patch(`${BASE_URL}/invoices/InPaymentBatch?${queryString}`, {})
    .then((r) => r.data);
};

export const getProcessFiles = (filter?: ProcessFileFilter) => {
  return api
    .get<PagedResponse<ProcessFile[]>>(`${BASE_URL_V2}/processfiles`, {
      params: createProcessFilterParams(filter),
    })
    .then((r) => r.data);
};

const createProcessFilterParams = (filter?: ProcessFileFilter) => {
  if (!filter) {
    return;
  }
  const { excludeStatusses, ...baseFilter } = filter;
  var params = createFilterParams(baseFilter);
  if (!params) {
    return;
  }

  if (excludeStatusses) {
    for (let status of excludeStatusses) {
      params.append('excludeStatusses', status);
    }
  }

  return params;
};

export const deleteProcessFile = (id: string) => {
  return api
    .delete<ProcessFile>(`${BASE_URL}/processfiles/${id}`)
    .then((r) => r.data);
};
export const rejectProcessFile = (
  id: string,
  reason?: string,
  description?: string,
) => {
  return api
    .patch<ProcessFile>(`${BASE_URL_V2}/processfiles/${id}/reject`, {
      reason,
      description,
    })
    .then((r) => r.data);
};
export const rejectProcessFileBatch = (request: BatchRejectRequest[]) => {
  return api
    .patch<ProcessFile>(`${BASE_URL_V2}/processfiles/batch/reject`, request)
    .then((r) => r.data);
};

export const reprocessProcessFile = (id: string, force: boolean = false) => {
  return api
    .patch<ProcessFile[]>(`${BASE_URL}/processfiles/${id}/reprocess`, {
      force,
    })
    .then((r) => r.data);
};
export const reprocessProcessFileBatch = (request: BatchReprocessRequest[]) => {
  return api
    .patch<ProcessFile>(`${BASE_URL}/processfiles/batch/reprocess`, request)
    .then((r) => r.data);
};
export const getIsInPaymentInvoices = () => getInvoices({ isInPayment: true });

export const getIncomingFileSourceFile = (id: string) =>
  api.get<ImportInvoice>(`${BASE_URL}/incomingfiles/${id}`).then((r) => r.data);

export const getProcessFileSourceFile = (id: string) =>
  api
    .get<ImportInvoice>(`${BASE_URL}/processfiles/${id}/source`)
    .then((r) => r.data);

export const getAllInvoicesWithouthRelation = () =>
  api
    .get<InvoiceWithouthRelation[]>(
      `${BASE_URL}/processfiles/unknown-relations/`,
    )
    .then((r) => r.data);

export const getInvoiceWithouthRelation = (id: string) =>
  api
    .get<InvoiceWithouthRelation>(
      `${BASE_URL}/processfiles/${id}/unknown-relation/`,
    )
    .then((r) => r.data);

export const getInvoicesZipFile = (filename: string, invoices: number[]) =>
  api
    .post(
      `${BASE_URL}/files/zipfile`,
      {
        fileName: filename,
        invoiceIds: invoices,
      },
      {
        responseType: 'blob',
        timeout: 60000,
      },
    )
    .then((r) => r.data);

export const postReprocessInvoiceWithouthRelation = (id: string) =>
  api.post(`${BASE_URL}/processfiles/${id}/unknown-relation/reprocess`, {
    reprocessRelated: true,
  });

export const postReprocessInvoiceWithouthRelations = (identifiers: RelationIdentifier[]) =>
  api.post(`${BASE_URL}/processfiles/unknown-relations/reprocess`,
    identifiers.map((x) => ({ identifier: x.identifier, identifierType: x.identifierType }))
  );

export const editInvoice = (id: number, data: InvoiceUpdateRequest) =>
  api.put(`${BASE_URL}/invoices/${id}`, data).then((r) => r.data);

export const setBulkHandled = (ids: number[], isHandled: boolean) =>
  api
    .patch(`${BASE_URL}/invoices/bulk`, { ids, isHandled })
    .then((r) => r.data);

export const postUpdateUbl = (id: string, relation: Relation) =>
  api.post(`${BASE_URL_V2}/processfiles/${id}/ubl-update`, relation)
  .then((r) => r.data);

export const updateAndAttachUbl = (
  invoiceId: string,
  relationId: string,
  identifiers: RelationIdentifier[],
) =>
  api
    .post(`${BASE_URL_V2}/processfiles/${invoiceId}/ubl-attach/${relationId}`,
      identifiers.map((x) => ({ identifier: x.identifier, identifierType: x.identifierType }))
    )
    .then((r) => r.data);