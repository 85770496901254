import { getRelations } from '@/lib/adapters/users-adapter';
import { CustomButton, CustomColumnType, CustomTable } from '@/lib/components';
import { SortOrder } from '@/lib/types';
import { IdentifierCategory, RelationType } from '@/lib/types/enums';
import { Relation, RelationFilter, RelationIdentifier } from '@/lib/types/user';
import { getOwnerFilterBase } from '@/lib/utils/dynamic-table-filter';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImportModal } from './_components/ImportModal';

const baseColumnSettings: CustomColumnType<Relation> = {
  enableDefaultSorter: false,
  sorter: () => 0,
  onFilter: (_, __) => true,
  filterMultiple: false,
};

export const AgrinotaImport = () => {
  const { t } = useTranslation();
  const [relations, setRelations] = useState<Relation[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedRelation, setSelectedRelation] = useState<Relation>();
  const [modalVisible, setModalVisible] = useState(false);

  const [tableSettings, setTableSettings] = useState<RelationFilter>({
    page: 1,
    pageSize: 10,
    orderField: 'name',
    order: SortOrder.Descending,
    exactMatch: false,
    type: RelationType.Customer,
  });

  const [total, setTotal] = useState(0);

  const fetchData = async (tableSettings: RelationFilter) => {
    setLoading(true);
    try {
      const relations = await getRelations(tableSettings);
      setTotal(relations.total);
      setRelations(relations.data);
    } catch (e) {
      console.log(e);
      showNotification('error', 'Fout tijdens ophalen relaties');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(tableSettings);
  }, [tableSettings]);

  const showImportForm = (relation: Relation) => {
    setSelectedRelation(relation);
    setModalVisible(true);
  };

  const tableColumns: CustomColumnType<Relation>[] = [
    {
      ...baseColumnSettings,
      title: t('relation.customerNumber'),
      key: 'customerNumber',
      dataIndex: 'identifiers',
      sorter: false,
      render: (identifiers: RelationIdentifier[]) =>
        identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.CUSTOMER,
        )?.identifier,
    },
    {
      ...baseColumnSettings,
      title: t('relation.name'),
      dataIndex: 'name',
      width: 400,
    },
    {
      ...baseColumnSettings,
      title: t('relation.address'),
      dataIndex: 'street',
      render: (value, record) =>
        `${record.street || ''} ${record.houseNumber || ''}`,
      width: 300,
    },
    {
      ...baseColumnSettings,
      title: t('relation.city'),
      dataIndex: 'city',
      width: 200,
    },
    {
      ...baseColumnSettings,
      title: t('relation.coc'),
      key: 'coc',
      dataIndex: 'identifiers',
      sorter: false,
      render: (identifiers: RelationIdentifier[]) =>
        identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.COC,
        )?.identifier,
    },
    {
      ...baseColumnSettings,
      title: t('relation.vat'),
      key: 'vat',
      dataIndex: 'identifiers',
      sorter: false,
      render: (identifiers: RelationIdentifier[]) =>
        identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.VAT,
        )?.identifier,
    },
    {
      title: '',
      align: 'right',
      render: (_, relation) => (
        <CustomButton
          type="link"
          onClick={() => showImportForm(relation)}
          toolTipKey="agrinotaimport.action.showImportForm"
          icon={<FontAwesomeIcon icon="file-import" />}
        />
      ),
      width: 50,
      enableDefaultSearch: false,
    },
  ];

  const onTableChange = async (pagination, filters, sorter) => {
    const filter: RelationFilter = await getOwnerFilterBase(
      pagination,
      filters,
      sorter,
      tableSettings.pageSize,
      'deliveryDate',
    );
    filter.cocNumber = filters.coc?.[0] || '';
    filter.vatNumber = filters.vat?.[0] || '';
    filter.customerNumber = filters.customerNumber?.[0] || '';

    setTableSettings((current) => ({ ...current, ...filter }));
  };

  return (
    <>
      <CustomTable
        rowKey="id"
        style={{ marginTop: '2rem' }}
        loading={isLoading}
        columns={tableColumns}
        dataSource={relations}
        onChange={onTableChange}
        pagination={{
          current: tableSettings.page,
          pageSize: tableSettings.pageSize,
          hideOnSinglePage: true,
          total: total,
          onChange: () => { },
        }}
        size="small"
      />
      <ImportModal
        onRelationFormClose={() => setModalVisible(false)}
        visible={modalVisible}
        relation={selectedRelation}
      />
    </>
  );
};
