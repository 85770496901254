import {
  CurrencyDisplay,
  CustomButton,
  DocumentViewModal,
} from '@/lib/components';
import { Invoice } from '@/lib/types';
import { dateTimeFormatter } from '@/lib/utils/formatters';
import { MinusCircleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Col, Row } from 'antd';

type CreditRowProps = {
  invoice: Invoice;
  remove: (invoice: Invoice) => void;
};

export const CreditRow = ({ invoice, remove }: CreditRowProps) => {
  return (
    <Row gutter={10}>
      <Col span={1}></Col>
      <Col span={4}>{dateTimeFormatter.toDateOnly(invoice.invoiceDate)}</Col>
      <Col span={5}>{invoice.invoiceNumber}</Col>

      <Col span={10}>
        <DocumentViewModal id={invoice.sourceIdentifier} idType="processfile" />
        <Badge count={<MinusCircleFilled />} offset={[-3, 3]} style={{ color: '#f5222d', fontSize: '18px' }}>
          <CustomButton
            shape="circle"
            color="secondary"
            ghost
            toolTipKey="payment.action.deleteInvoice"
            onClick={() => remove(invoice)}
            icon={<FontAwesomeIcon icon={["far", "credit-card"]} />}
          />
        </Badge>
      </Col>

      <Col span={4} style={{ textAlign: 'right' }}>
        <span style={{ color: 'red' }}>
          <CurrencyDisplay amount={invoice.amount} />
        </span>
      </Col>
    </Row>
  );
};
