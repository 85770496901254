import { setIsInPayment, setIsInPaymentBatch } from '@/lib/adapters/invoice-adapter';
import { createSepaPayment } from '@/lib/adapters/payment-adapter';
import { hasAccessAtom, payableInvoiceCountAtom } from '@/lib/atoms/atoms';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { useRelationContext } from '@/lib/context';
import { showNotification } from '@/lib/utils/showNotification';

import {
  Accent,
  CurrencyDisplay,
  CustomButton,
  DocumentViewModal,
} from '@/lib/components';
import {
  useGetBankAccounts,
  useGetPaymentSettings,
  useGetPayments,
  useGetRenameableRelations,
} from '@/lib/queries';
import { useGetInPaymentInvoices } from '@/lib/queries/invoices/invoices';

import { Heading } from '@/lib/components/core/typography/heading';
import {
  Invoice,
  InvoiceType,
  NettingPaymentEntry,
  PaymentEntry,
} from '@/lib/types';
import {
  PaymentInvoiceItem,
  PaymentProcessStatus,
  PaymentSetting,
} from '@/lib/types/payment';
import { roundToTwoDecimals } from '@/lib/utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Affix,
  Alert,
  Badge,
  Col,
  Divider,
  Form,
  List,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
} from 'antd';
import dayjs from 'dayjs';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentHeader } from './_components/InPaymentHeader';
import { PaymentInvoice } from './_components/paymentInvoice';
import {
  getNetInvoiceAmount,
  getPaymentInfo,
} from './_helpers/paymentInvoiceHelper';
import styles from './styles.module.scss';
import { MinusCircleFilled } from '@ant-design/icons';
const { Option } = Select;

export const InPayment = () => {
  const { t } = useTranslation();
  const setPayableInvoiceCount = useUpdateAtom(payableInvoiceCountAtom);
  const hasAccess = useAtomValue(hasAccessAtom);
  const { isFrozen } = useRelationContext();
  const [sepaGenerateLoading, setSepaGenerateLoading] = useState(false);
  const [unusedCreditInvoices, setUnusedCreditInvoices] = useState<Invoice[]>(
    [],
  );

  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [showErrorMessages, setShowErrorMessages] = useState(false);
  const {
    data: invoices,
    refetch: refetchInvoices,
    isLoading: invoicesIsLoading,
  } = useGetInPaymentInvoices();

  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [partialPaymentAmount, setPartialPaymentAmount] = useState<number>();

  const invoicesForm = form.getFieldValue('invoices');
  const allInvoiceIds = invoicesForm?.map(invoice => invoice.invoice.invoiceId) || []
  const isCheckAll = checkedList.length === allInvoiceIds.length && allInvoiceIds.length > 0;
  const isIndeterminate = checkedList.length > 0 && checkedList.length < allInvoiceIds.length;
  const noCheckedBoxes = checkedList.length === 0;

  useEffect(() => {
    updatePartialPaymentCount(invoicesForm);
    checkErrors();
  }, [checkedList]);

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? allInvoiceIds : []);
  };

  const onCheck = (id, checked) => {
    setCheckedList((prev) =>
      checked ? [...prev, id] : prev.filter((invoiceId) => invoiceId !== id)
    );
  };
  const { data: paymentSettings, isLoading: paymentSettingsIsLoading } =
    useGetPaymentSettings();
  const { data: bankAccounts, isLoading: bankAccountsIsLoading } =
    useGetBankAccounts();
  const {
    data: paymentsInProgress,
    isLoading: paymentsIsLoading,
    isFetching: paymentsIsFetching,
    refetch: refetchPayments,
  } = useGetPayments({
    processStatus: PaymentProcessStatus.InProgress,
  });

  const { data: renameableRelationIds, isLoading: renameableIsLoading } =
    useGetRenameableRelations();

  const createPaymentInvoices = (
    invoices: Invoice[],
  ): {
    paymentInvoices: PaymentInvoiceItem[];
    unusedCreditInvoices: Invoice[];
  } => {
    const formValues = form.getFieldsValue(['invoices']);
    const excludedInvoices =
      formValues.invoices?.filter((x) => x.isExcluded) ?? [];

    const isExcluded = (invoiceId: number) => {
      return !!excludedInvoices.find((x) => x.invoice.invoiceId === invoiceId);
    };

    // Netting is determined in the following steps:
    // 1. Check if the invoice has the same counterRelationId
    // 2. Check the amount the invoice
    // If the invoice < creditNota - do not use
    // If the invoice >= creditNota - add it to the invoice
    const purchaseInvoices = invoices
      .filter((x) => x.type.toString() === InvoiceType[InvoiceType.Purchase])
      .sort((a, b) => new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime());

    let creditInvoices = invoices.filter(
      (x) => x.type.toString() === InvoiceType[InvoiceType.CreditNote],
    );

    const newPaymentInvoices: PaymentInvoiceItem[] = [];

    for (let invoice of purchaseInvoices) {
      let restAmount = invoice.amount;

      const nettingInvoices: Invoice[] = [];

      for (let creditInvoice of creditInvoices) {
        if (creditInvoice.counterRelationId !== invoice.counterRelationId) {
          continue;
        }
        const absCreditAmount = Math.abs(creditInvoice.amount);

        if (restAmount >= absCreditAmount) {
          nettingInvoices.push(creditInvoice);
          restAmount -= absCreditAmount;
        }
      }

      // Remove the used creditnote from the list
      creditInvoices = creditInvoices.filter(
        (x) => !nettingInvoices.find((y) => y.invoiceId === x.invoiceId),
      );

      const paymentSetting = getPaymentSettingForInvoice(invoice);
      const paymentInfo = getPaymentInfo(invoice, paymentSetting);

      let netInvoiceAmount = getNetInvoiceAmount(invoice, nettingInvoices);

      // If there is an discount, use it as initialPayment value
      const discountedValue = paymentInfo.discount
        ? roundToTwoDecimals((invoice.amount / 100) * paymentInfo.discount)
        : 0;

      const initialPayment = roundToTwoDecimals(
        netInvoiceAmount - discountedValue,
      );

      const receiverName = invoice.supplier.name;
      const initialPayments = [
        {
          ...paymentInfo,
          value: initialPayment,
        },
      ];
      newPaymentInvoices.push({
        invoice,
        nettingInvoices,
        isExcluded: isExcluded(invoice.invoiceId),
        paymentSetting,
        receiverName,
        paymentTerm: paymentInfo?.paymentTerm,
        discount: paymentInfo?.discount,
        netInvoiceAmount: netInvoiceAmount,
        partialPayments: initialPayments,
      });
    }
    setCheckedList(newPaymentInvoices.map(invoice => invoice.invoice.invoiceId))

    return {
      paymentInvoices: newPaymentInvoices,
      unusedCreditInvoices: creditInvoices,
    };
  };

  const removeCreditInvoice = async (invoice: Invoice) => {
    await setIsInPayment(invoice.invoiceId, !invoice.isInPayment);
    refetchInvoices();
  };

  useEffect(() => {
    if (bankAccounts.length) {
      form.setFieldValue('iban', bankAccounts[0].iban);
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (invoicesIsLoading || paymentSettingsIsLoading) {
      return;
    }

    const { paymentInvoices, unusedCreditInvoices } = createPaymentInvoices(
      invoices.data,
    );

    setPayableInvoiceCount(invoices.data.length);
    setUnusedCreditInvoices(unusedCreditInvoices);
    form.resetFields();
    form.setFieldValue(['invoices'], paymentInvoices);
  }, [invoices, paymentSettings]);

  useEffect(() => {
    if (!paymentsInProgress?.length) {
      refetchInvoices();
    }
  }, [paymentsInProgress]);

  const createSepa = async (values) => {
    if (paymentsInProgress?.length) {
      showNotification(
        'warning',
        t('api.payment.alreadyInProgress', { ns: 'errors' }),
      );
      return;
    }

    const includedInvoices = values.invoices.filter(
      (x) =>
        !x.isExcluded &&
        (x.partialPayments?.length || x.nettingInvoices?.length) &&
        checkedList.includes(x.invoice.invoiceId)
    );

    try {
      setSepaGenerateLoading(true);
      if (!includedInvoices || !includedInvoices.length) {
        showNotification(
          'warning',
          t('api.payment.noInvoicesSelected', { ns: 'errors' }),
        );
        return;
      }

      const isBatchSelected = values.isBatchSelected ?? false;

      const partialPaymentAmount = Array.isArray(includedInvoices)
        ? includedInvoices.reduce(
          (acc, cv) => (acc += cv.partialPayments.length),
          0,
        )
        : 0;

      if (includedInvoices.length > 75 || partialPaymentAmount > 75) {
        showNotification(
          'warning',
          t('api.payment.sepaMaxBatchAmount', { ns: 'errors' }),
        );
        return;
      }

      let paymentEntries: PaymentEntry[] = [];
      let nettingPaymentEntries: NettingPaymentEntry[] = [];

      for (let paymentInvoice of includedInvoices) {
        paymentEntries.push(
          ...paymentInvoice.partialPayments.map((payment) => ({
            receiverIban: payment.bankAccount.iban,
            receiverBic: payment.bankAccount.bic,
            receiverName: paymentInvoice.receiverName,
            amount: payment.value,
            discount: paymentInvoice.discount,
            executionDate: new Date(dayjs(payment.date).format('YYYY-MM-DD')),
            invoiceId: paymentInvoice.invoice.invoiceId,
          })),
        );

        nettingPaymentEntries.push(
          ...paymentInvoice.nettingInvoices.map((nettingInvoice) => ({
            invoiceId: nettingInvoice.invoiceId,
            receiverName: paymentInvoice.receiverName,
            nettingInvoiceId: paymentInvoice.invoice.invoiceId,
          })),
        );
      }

      if (!paymentEntries || !paymentEntries.length) {
        throw new Error('Minimaal 1 betaling moet gemaakt worden.');
      }

      const sepa = {
        iban: values.iban,
        isBatchSelected: isBatchSelected,
        paymentEntries,
        nettingPaymentEntries,
      };

      await createSepaPayment(sepa);
      showNotification('info', `SEPA wordt gegenereerd.`);
      await refetchPayments();
      await refetchInvoices();
      resetExcluded();
    } catch (e: any) {
      showNotification(
        'error',
        'Fout tijdens genereren SEPA. Probeer opnieuw.',
      );
    } finally {
      setSepaGenerateLoading(false);
    }
  };

  const resetExcluded = () => {
    const { invoices } = form.getFieldsValue();
    const invoicesClone = [...invoices];

    form.setFieldsValue({
      invoices: invoicesClone.map((x) => ({ ...x, isExcluded: false })),
    });
  };

  const getPaymentSettingForInvoice = (
    invoice: Invoice,
  ): PaymentSetting | undefined => {
    const counterRelationId = invoice.counterRelationId;
    if (!counterRelationId) {
      return;
    }
    return paymentSettings.find(
      (x) => x.counterRelationId === counterRelationId,
    );
  };

  const getTotalValueOfCheckedRows = () => {
    const fields = form.getFieldsValue(['invoices']);
    const checkedInvoices = fields.invoices?.filter((x) => checkedList.includes(x.invoice.invoiceId));
    const includedInvoices = checkedInvoices?.filter((x) => !x.isExcluded);
    if (!includedInvoices || !includedInvoices.length) {
      return 0;
    }
    const total = includedInvoices.reduce((total, currentValue) => {
      if (
        !currentValue?.partialPayments ||
        !currentValue.partialPayments.length
      ) {
        return total;
      }
      const subTotal = currentValue.partialPayments.reduce(
        (partialTotal, partialCurrent) =>
          partialTotal + (partialCurrent.value ?? 0),
        0,
      );
      return total + subTotal;
    }, 0);
    return total;
  };

  const handleChange = (changedValues, allValues) => {
    updatePartialPaymentCount(allValues.invoices);
    checkErrors();
  }

  const handleSubmit = () => {
    checkErrors();
    if (errorMessages.length == 0) {
      createSepa(form.getFieldsValue())
    }
    else {
      showErrorNotification();
    }
  }

  const updatePartialPaymentCount = (invoices) => {
    if (!invoices) return;

    const totalPartialPayments = invoices
      .filter(invoice => checkedList.includes(invoice.invoice.invoiceId)) // Only count checked invoices
      .reduce((sum, invoice) => sum + (invoice.partialPayments?.length || 0), 0);

    setPartialPaymentAmount(totalPartialPayments);
  };

  const showErrorNotification = () => {
    setShowErrorMessages(true);
    showNotification('error', 'Nog niet alles is goed ingevuld');
  };

  const onHandleSetIsInPaymentBatch = async () => {
    try {
      await setIsInPaymentBatch(checkedList, false);
      await refetchInvoices();
      form.resetFields();
    } catch (e) {
      showNotification('error', 'Verwijderen mislukt');
    }
  };

  const checkErrors = async () => {
    try {
      if (noCheckedBoxes) {
        setErrorMessages([]);
      }
      else {
        const allFields = form.getFieldsValue();
        const selectedIndexes = allFields.invoices
          .map((invoice, index) => (checkedList.includes(invoice.invoice.invoiceId) ? index : null))
          .filter((index) => index !== null); // Avoids creating unnecessary empty elements

        const fieldsToValidate: any[] = [['iban']];

        selectedIndexes.forEach(index => {
          const invoice = allFields.invoices[index];
          fieldsToValidate.push(['invoices', index, 'toPay']);

          invoice.partialPayments.forEach((_, pIndex) => {
            fieldsToValidate.push(['invoices', index, 'partialPayments', pIndex, 'value']);
            fieldsToValidate.push(['invoices', index, 'partialPayments', pIndex, 'bankAccount']);
            fieldsToValidate.push(['invoices', index, 'partialPayments', pIndex, 'date']);
          });
        });
        await form.validateFields(fieldsToValidate);
        setErrorMessages([])
      }
    } catch (error: any) {
      const errors: string[] = [];
      error.errorFields.forEach((element) => {
        errors.push(element.name[element.name.length - 1]);
      });

      const errorMappings = {
        date: 'api.payment.banner.date',
        iban: 'api.payment.banner.iban',
        bankAccount: 'api.payment.banner.bankaccount',
        toPay: 'api.payment.banner.higher',
        value: 'api.payment.banner.minimum',
      };

      let uniqueErrors = [];

      errors.forEach((error) => {
        let errMessage = errorMappings[error];
        if (errMessage) uniqueErrors.push(t(errMessage, { ns: 'errors' }));
      });
      setErrorMessages([...new Set<string>(uniqueErrors)]);
    }
  };

  const isLoading =
    invoicesIsLoading ||
    paymentSettingsIsLoading ||
    renameableIsLoading ||
    bankAccountsIsLoading ||
    paymentsIsLoading;

  const sepaInProgress =
    !!paymentsInProgress?.length || sepaGenerateLoading || false;

  return (
    <Spinal initializing={isLoading} loading={paymentsIsFetching}>
      <Heading text={t('payment.title')} />
      <Form
        form={form}
        layout="vertical"
        onValuesChange={handleChange}
      >
        <Affix>
          <div style={{
            background: 'white',
            padding: '15px',
            borderRadius: '8px',
            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
            marginLeft: '-10px',
            marginRight: '-10px'
          }}>
            <Row align="middle" gutter={16}>
              <Col flex={1}>
                <Form.Item shouldUpdate>
                  {() => (
                    <>
                      <span>{t('payment.selectedRows')}: {partialPaymentAmount}</span>
                      <br />
                      <span>{t('payment.totalValue')}: </span>
                      <CurrencyDisplay amount={getTotalValueOfCheckedRows()} />
                    </>
                  )}
                </Form.Item>
                <Popconfirm
                  icon={null}
                  title={
                    <div style={{ width: '235px' }}>
                      <Form.Item
                        label={<Accent color="tertiary">{t('label.chooseBankAccount')}</Accent>}
                        name="iban"
                        initialValue={bankAccounts.length === 1 ? bankAccounts[0].iban : undefined}
                        rules={[{ required: true }]}
                        style={{ marginTop: 8 }}
                        labelCol={{ span: 24 }}
                      >
                        <Select
                          style={{ width: '230px', fontSize: '1rem' }}
                          placeholder={t('payment.ibanSelect.actions.select')}
                          bordered={false}
                          disabled={sepaInProgress}
                          suffixIcon={<FontAwesomeIcon icon={['fas', 'angle-down']} size="lg" className={styles.select} />}
                        >
                          {bankAccounts?.map((bankAccount) => (
                            <Option key={bankAccount.id} value={bankAccount.iban}>
                              {bankAccount.iban}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item shouldUpdate>
                        {() => (
                          <div>
                            <span>Totaal</span>
                            <Divider style={{ marginTop: 0, marginBottom: 15, borderWidth: '3px' }} />
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <span>Betalingen: </span>
                              {checkedList.length}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <span>Bedrag: </span>
                              <CurrencyDisplay amount={getTotalValueOfCheckedRows()} />
                            </div>
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  }
                  onConfirm={() => handleSubmit()}
                  placement="bottomLeft"
                  okButtonProps={{ shape: 'round' }}
                  cancelButtonProps={{ shape: 'round' }}
                >
                  <CustomButton
                    icon={<FontAwesomeIcon icon={['far', 'credit-card']} size="sm" />}
                    shape="round"
                    type="primary"
                    color="secondary"
                    loading={sepaGenerateLoading}
                    toolTipKey="payment.action.createSepa"
                    disabled={hasAccess.isAccountManager || isFrozen || sepaInProgress || noCheckedBoxes}
                  >
                    {t('payment.sepa')}
                  </CustomButton>
                </Popconfirm>

              </Col>
              <Col flex='auto' style={{ marginRight: "20%" }}>
                <div>
                  {errorMessages.length && showErrorMessages ? (
                    <Alert
                      description={
                        <ul>
                          {errorMessages.map((msg) => (
                            <li>{msg}</li>
                          ))}
                        </ul>
                      }
                      type="error"
                      showIcon
                    />
                  ) : null}
                </div>
              </Col>
              {unusedCreditInvoices.length > 0 && (
                <Col>
                  <Popover
                    title={t('payment.unusedCreditInvoice')}
                    content={
                      <List
                        dataSource={unusedCreditInvoices}
                        split={false}
                        bordered={false}
                        size="small"
                        renderItem={(invoice) => (
                          <List.Item>
                            {invoice.invoiceNumber}
                            <DocumentViewModal
                              id={invoice.sourceIdentifier}
                              idType="processfile"
                            />
                            <Badge count={<MinusCircleFilled />} offset={[-3, 3]} style={{ color: '#f5222d', fontSize: '18px' }}>
                              <CustomButton
                                shape="circle"
                                color="secondary"
                                ghost
                                toolTipKey="payment.action.deleteInvoice"
                                onClick={() => removeCreditInvoice(invoice)}
                                icon={<FontAwesomeIcon icon={["far", "credit-card"]} />}
                              />
                            </Badge>
                          </List.Item>
                        )}
                      />
                    }
                  >
                    <Badge count={unusedCreditInvoices.length}>
                      <CustomButton
                        color="primary"
                        shape="circle"
                        toolTipKey="payment.action.deleteInvoice"
                        icon={<FontAwesomeIcon icon="exclamation" />}
                      />
                    </Badge>
                  </Popover>
                </Col>
              )}
              <Popconfirm
                title={t('payment.setInPaymentBatch', { totalCount: checkedList.length })}
                placement='topRight'
                onConfirm={() => onHandleSetIsInPaymentBatch()}
                okButtonProps={{
                  shape: 'round',
                }}
                cancelButtonProps={{
                  shape: 'round',
                }}
              >
                <Badge count={<MinusCircleFilled />} offset={[-3, 3]} style={{ color: '#f5222d', fontSize: '18px' }}>
                  <CustomButton
                    ghost
                    disabled={hasAccess.isAccountManager || isFrozen || sepaInProgress || noCheckedBoxes}
                    shape="circle"
                    toolTipKey="payment.action.deleteInvoiceBatch"
                    color="secondary"
                    icon={<FontAwesomeIcon icon={["far", "credit-card"]} />}
                  />
                </Badge>
              </Popconfirm>

            </Row>
            {sepaInProgress ? (
              <div style={{ paddingTop: '10px' }}>
                <Alert
                  message={
                    <Space size="small">
                      <span>
                        {t('api.payment.alreadyInProgress', { ns: 'errors' })}
                      </span>
                      <CustomButton
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => refetchPayments()}
                      >
                        <FontAwesomeIcon icon="redo" rotation={270} />
                        {t('payment.action.refresh')}
                      </CustomButton>
                    </Space>
                  }
                  type="info"
                />
              </div>
            ) : null}
          </div>
        </Affix>
        <div style={{ paddingTop: '10px' }}>
          <PaymentHeader
            isCheckAll={isCheckAll}
            isIndeterminate={isIndeterminate}
            onCheckAllChange={onCheckAllChange}
          />
        </div>

        <div className={styles.rowContainer}>
          <Form.List name="invoices">
            {(fields, { add, remove }, { errors }) => {
              const onHandleRemove = async (invoice: Invoice, index) => {
                try {
                  remove(index);
                  await setIsInPayment(invoice.invoiceId, !invoice.isInPayment);
                  await refetchInvoices();
                  form.resetFields();
                } catch (e) {
                  showNotification('error', 'Verwijderen mislukt');
                }
              };

              const onHandleRemoveCreditInvoice = async (
                invoice: Invoice,
                index,
              ) => {
                try {
                  // Remove it from the fields
                  const { invoices } = form.getFieldsValue();
                  const invoicesClone = [...invoices];

                  invoicesClone[index].nettingInvoices = invoicesClone[
                    index
                  ].nettingInvoices.filter(
                    (x) => x.invoiceId !== invoice.invoiceId,
                  );
                  form.setFieldsValue({ invoices: invoicesClone });

                  await removeCreditInvoice(invoice);
                } catch {
                  showNotification('error', 'Verwijderen mislukt');
                }
              };

              const onHandleExclude = (exclude: boolean, index: number) => {
                try {
                  // Remove it from the fields
                  const { invoices } = form.getFieldsValue();
                  const invoicesClone = [...invoices];

                  invoicesClone[index].isExcluded = exclude;
                  form.setFieldsValue({ invoices: invoicesClone });
                } catch {
                  showNotification('error', 'Aanpassen mislukt');
                }
              };

              return (
                <>
                  {fields.map((field, index) => {
                    const paymentInvoice: PaymentInvoiceItem =
                      form.getFieldValue(['invoices', field.name]);
                    return (
                      <div className={styles.invoiceRow} key={index}>
                        <PaymentInvoice
                          paymentInvoice={paymentInvoice}
                          isChecked={checkedList.includes(paymentInvoice.invoice.invoiceId)}
                          onCheck={onCheck}
                          isRenameable={
                            renameableRelationIds.includes(
                              paymentInvoice?.invoice?.supplier.relationId,
                            ) || false
                          }
                          isDisabled={
                            hasAccess.isAccountManager || isFrozen || sepaInProgress
                          }
                          invoiceIndex={index}
                          path={[field.name]}
                          removeInvoice={() =>
                            onHandleRemove(paymentInvoice.invoice, index)
                          }
                          removeCreditInvoice={(invoice) =>
                            onHandleRemoveCreditInvoice(invoice, index)
                          }
                          handleExclude={(exclude: boolean) =>
                            onHandleExclude(exclude, index)
                          }
                        />
                      </div>
                    );
                  })}
                </>
              );
            }}
          </Form.List>
        </div>
      </Form>
    </Spinal>
  );
};
