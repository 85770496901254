import {
  deleteProcessFile,
  getProcessFiles,
  reprocessProcessFile,
} from '@/lib/adapters/invoice-adapter';
import { getRelations } from '@/lib/adapters/users-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  DocumentViewModal,
} from '@/lib/components';
import {
  ProcessFile,
  ProcessFileFilter,
  ProcessFileWithOwner,
  SortOrder,
} from '@/lib/types';
import { IdentifierCategory } from '@/lib/types/enums';
import { getOwnerFilterBase } from '@/lib/utils/dynamic-table-filter';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Popconfirm, Row, Space } from 'antd';
import { useAtomValue } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const baseColumnSettings: CustomColumnType<ProcessFileWithOwner> = {
  enableDefaultSorter: false,
  sorter: () => 0,
  onFilter: (_, __) => true,
  filterMultiple: false,
};

export const RejectedInvoices = () => {
  const { t } = useTranslation();

  const [files, setFiles] = useState<ProcessFileWithOwner[]>([]);
  const [total, setTotal] = useState(0);
  const { isAdmin } = useAtomValue(hasAccessAtom);
  const [isLoading, setLoading] = useState(true);
  const [tableSettings, setTableSettings] = useState<ProcessFileFilter>({
    page: 1,
    pageSize: 10,
    orderField: 'deliveryDate',
    order: SortOrder.Descending,
    exactMatch: false,
  });

  const fetchData = async (tableSettings: ProcessFileFilter) => {
    setLoading(true);
    try {
      const files = await getProcessFiles({
        ...tableSettings,
        excludeStatusses: [
          'Success',
          'Duplicate',
          'MigrationError',
          'None',
          'Error',
          'Reprocessing',
          'UnknownRelation',
        ],
      });
      setTotal(files.total);

      const relations = await getFileRelations(files.data);

      const filesWithOwner: ProcessFileWithOwner[] = files.data.map((x) => ({
        ...x,
        owner: relations.find((relation) => relation.id === x.ownerRelationId),
      }));
      setFiles(filesWithOwner);
    } catch (e) {
      console.log(e);
      showNotification('error', 'Fout tijdens ophalen facturen');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(tableSettings);
  }, [tableSettings]);

  const reprocessFile = async (id: string) => {
    try {
      await reprocessProcessFile(id);
      showNotification('success', 'Opnieuw ter verwerking gesteld.');
      fetchData(tableSettings);
    } catch {
      console.log('Fout tijdens herverwerken factuur.');
    }
  };

  const deleteFile = async (id: string) => {
    try {
      await deleteProcessFile(id);
      showNotification('success', 'Verwijderd.');
      fetchData(tableSettings);
    } catch {
      console.log('Fout tijdens verwijderen.');
    }
  };

  const downloadJson = async () => {
    try {
      var response = await getProcessFiles({
        excludeStatusses: [
          'Success',
          'Duplicate',
          'MigrationError',
          'None',
          'Error',
          'Reprocessing',
          'UnknownRelation',
        ],
      });

      const relations = await getFileRelations(response.data);

      const filesWithCustomerId = response.data.map((x) => ({
        customerRelationIdentifier: relations.find((relation) => relation.id === x.ownerRelationId)?.
          identifiers.find((identifier) => identifier.category == IdentifierCategory.CUSTOMER)?.identifier,
        ...x
      }));

      // Create a Blob from the JSON data
      const blob = new Blob([JSON.stringify(filesWithCustomerId, null, 2)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'rejectedInvoices.json';
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

    } catch {
      console.log('Fout tijdens ophalen alle process files.');
    }
  }

  const getFileRelations = async (data: ProcessFile[]) => {
    const relationIds = Array.from(
      new Set(
        data
          .filter((x) => x.ownerRelationId)
          .map((x) => x.ownerRelationId),
      ),
    );

    return relationIds.length
      ? (
        await getRelations({
          relationIds: relationIds,
        })
      )?.data
      : [];
  }

  const formatIdentifer = (identifier?: string, identifierType?: string) => {
    let str = '';

    if (identifier) {
      str += identifier;
    }
    if (identifierType) {
      str += `(${identifierType})`;
    }

    return str;
  };

  const tableColumns: CustomColumnType<ProcessFile>[] = [
    {
      ...baseColumnSettings,
      title: t('processFile.owner.customerNumber'),
      dataIndex: 'ownerCustomerNumber',
      render: (_, row: ProcessFileWithOwner) => {
        return row.owner?.identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.CUSTOMER,
        )?.identifier;
      },
      sorter: false,
    },
    {
      ...baseColumnSettings,
      title: t('processFile.deliveryDate'),
      dataIndex: 'created',
      width: 200,
      defaultRender: 'dateonly',
      defaultSearch: 'dateonly',
    },
    {
      ...baseColumnSettings,
      title: t('processFile.documentName'),
      dataIndex: 'documentName',
      width: 300,
    },
    {
      ...baseColumnSettings,
      title: t('processFile.uploadType'),
      dataIndex: 'uploadType',
      render: (value) => t(`uploadTypes.${value}`),
    },
    {
      ...baseColumnSettings,
      title: t('processFile.status'),
      dataIndex: 'status',
    },
    {
      ...baseColumnSettings,
      title: t('processFile.statusDescription'),
      dataIndex: 'statusDescription',
    },
    {
      ...baseColumnSettings,
      title: t('processFile.customerIdentifier'),
      dataIndex: 'customerIdentifier',
      render: (_, record) =>
        formatIdentifer(
          record.customerIdentifier,
          record.customerIdentifierType,
        ),
    },
    {
      ...baseColumnSettings,
      title: t('processFile.supplierIdentifier'),
      dataIndex: 'supplierIdentifier',
      render: (_, record) =>
        formatIdentifer(
          record.supplierIdentifier,
          record.supplierIdentifierType,
        ),
    },
    {
      width: 180,
      dataIndex: 'id',
      render: (id, row: ProcessFile) => (
        <>
          <DocumentViewModal id={id} idType="processfile" />

          <Popconfirm
            title={t('rejectedInvoices.actions.reprocess.content')}
            okButtonProps={{
              shape: 'round',
            }}
            cancelButtonProps={{
              shape: 'round',
            }}
            onConfirm={() => reprocessFile(id)}
          >
            <CustomButton
              toolTipKey="rejectedInvoices.action.reprocess"
              type="link"
              icon={<FontAwesomeIcon icon="redo" />}
            />
          </Popconfirm>

          {row.status.toLowerCase() === 'rejected' && (
            <Popconfirm
              title={t('rejectedInvoices.actions.delete.content')}
              onConfirm={() => deleteFile(id)}
              okButtonProps={{
                shape: 'round',
              }}
              cancelButtonProps={{
                shape: 'round',
              }}
            >
              <CustomButton
                type="link"
                toolTipKey="rejectedInvoices.action.delete"
                danger
                shape="circle"
                size="small"
                icon={<FontAwesomeIcon icon="trash-alt" />}
              />
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  const onTableChange = async (pagination, filters, sorter) => {
    const filter = await getOwnerFilterBase(
      pagination,
      filters,
      sorter,
      tableSettings.pageSize,
      'deliveryDate',
    );
    setTableSettings((current) => ({ ...current, ...filter }));
  };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify="end">
          <Col>
            {isAdmin && (
              <CustomButton
                type="primary"
                color="secondary"
                onClick={downloadJson}
              >
                {t('rejectedInvoices.actions.download')}
              </CustomButton>
            )}
          </Col>
        </Row>
        <CustomTable
          rowKey="id"
          style={{ marginTop: '2rem' }}
          loading={isLoading}
          onChange={onTableChange}
          columns={tableColumns}
          dataSource={files}
          pagination={{
            current: tableSettings.page,
            pageSize: tableSettings.pageSize,
            hideOnSinglePage: true,
            total: total,
            onChange: () => { },
          }}
          size="small"
        />
      </Space>
    </>
  );
};
