import {
  blockRelation,
  freezeRelation,
  putRelation,
  unFreezeRelation,
  unblockRelation,
} from '@/lib/adapters/users-adapter';
import { CustomButton } from '@/lib/components';
import { IdentifierType, SortOrder } from '@/lib/types';
import { IdentifierCategory, RelationType } from '@/lib/types/enums';
import { Relation, RelationFilter } from '@/lib/types/user';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Radio, Row } from 'antd';
import { useState } from 'react';

import { useGetIdentifierTypes, useGetRelations } from '@/lib/queries';
import { RelationsTable } from '../../../lib/components/relations/relationsTable';
import { AddRelation } from './_components/addRelation';
import { ConvertRelation } from './_components/convertRelation';
import { EditModalProps, EditRelation } from './_components/editRelation';

const RESET_MODALSTATE: EditModalProps = {
  visible: false,
  loading: false,
  relation: undefined
};

export const Relations = () => {
  const [modalState, setModalState] =
    useState<EditModalProps>(RESET_MODALSTATE);

  const [tableSettings, setTableSettings] = useState<RelationFilter>({
    page: 1,
    pageSize: 10,
    orderField: 'name',
    order: SortOrder.Descending,
    exactMatch: false,
    type: RelationType.Customer,
  });

  const {
    data: relations,
    isFetching,
    refetch,
  } = useGetRelations(tableSettings);

  const {
    data: identifierTypes,
  } = useGetIdentifierTypes();

  const handleChangeType = (e) =>
    setTableSettings((current) => ({
      ...current,
      type: e.target.value,
      page: 1,
    }));

  const showRelationForm = (relation: Relation) => {
    setModalState({
      visible: true,
      loading: false,
      relation
    });
  };

  const onRelationFormClose = () => setModalState(RESET_MODALSTATE);

  const onRelationDelete = async () => {
    if (!modalState.relation?.id) {
      return;
    }

    setModalState((prev) => ({ ...prev, loading: true }));
    try {
      refetch();
      setModalState(RESET_MODALSTATE);
    } catch {
      setModalState((prev) => ({ ...prev, loading: false }));
    }
    setModalState(RESET_MODALSTATE);
  };

  const onRelationFormSuccess = async (relation: Relation) => {
    for (const value of relation.identifiers) {
      if (typeof value.identifierType !== 'string') {
        const currentIdentifierType = identifierTypes?.find(x => x.id === parseInt(value.identifierType))
        value.category = currentIdentifierType?.category;
        value.identifierType = currentIdentifierType?.type!;
      }
    }
    if (!modalState.relation?.id) {
      return;
    }
    const relationId = modalState.relation?.id;

    try {
      if (modalState.relation.isBlocked !== relation.isBlocked) {
        relation.isBlocked
          ? await blockRelation(relationId)
          : await unblockRelation(relationId);
      }
      if (modalState.relation.isFrozen !== relation.isFrozen) {
        relation.isFrozen
          ? await freezeRelation(relationId)
          : await unFreezeRelation(relationId);
      }
      await putRelation(relationId, relation);

      refetch();
      setModalState(RESET_MODALSTATE);
      showNotification('success', 'Succesvol uitgevoerd');
    } catch {
      showNotification('error', 'Fout tijdens opslaan');
      setModalState((prev) => ({ ...prev, loading: false }));
    }
  };

  return (
    <>
      <Row justify="space-between" gutter={[16, 16]} align="bottom">
        <Col>
          Toon:
          <Radio.Group
            disabled={isFetching}
            style={{ marginLeft: 10 }}
            optionType="button"
            onChange={handleChangeType}
            defaultValue={tableSettings.type}
            buttonStyle="solid"
          >
            <Radio.Button value="Customer">Klanten</Radio.Button>
            <Radio.Button value="External">Externe bedrijven</Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <AddRelation fetchData={() => refetch()} />
        </Col>
        <Col span={24}>
          <RelationsTable
            isLoading={isFetching}
            relations={relations.data}
            tableSettings={tableSettings}
            setTableSettings={(settings) => setTableSettings(settings)}
            total={relations?.total}
            renderActions={(relation) => (
              <>
                <CustomButton
                  type="link"
                  onClick={() => showRelationForm(relation)}
                  toolTipKey="relations.action.edit"
                  icon={<FontAwesomeIcon icon="pencil-alt" />}
                />
                <ConvertRelation
                  relation={relation}
                  onSubmit={() => refetch()}
                />
              </>
            )}
          />
        </Col>
      </Row>

      <EditRelation
        {...modalState}
        identifierTypes={identifierTypes}
        onRelationFormClose={onRelationFormClose}
        onRelationFormSuccess={onRelationFormSuccess}
        onRelationDelete={onRelationDelete}
      />
    </>
  );
};
