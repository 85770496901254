import { getLocations } from '@/lib/adapters/users-adapter';
import { Accent, CustomButton } from '@/lib/components';
import { LocationProps } from '@/lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, Row, Select, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MailboxInput } from '../mailboxInput';
import { CustomStepProps, ExtraData } from './types';

export const Extra: FC<CustomStepProps> = ({
  identifier,
  setCurrentPage,
  extraData,
  setExtraData,
}) => {
  const { t } = useTranslation('management', {
    keyPrefix: 'relations.addRelation.steps.extra',
  });

  const [form] = Form.useForm();
  const [locations, setLocations] = useState<LocationProps[]>([]);
  const [isLoading, setLoading] = useState(true);

  const goNext = (values) => {
    const extraData: ExtraData = {
      locationId: values.locationId,
      locationName: locations.find((x) => x.id === values.locationId)?.name,
      theme: values.theme,
      mailbox: values.mailbox,
      safeEmailAddresses: values.safeEmailAddresses,
    };

    setExtraData?.(extraData);
    setCurrentPage?.((prev) => prev + 1);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const locations = await getLocations();
      setLocations(locations);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Accent type="h2" color="secondary">
        {t('title')}
      </Accent>
      <Form
        initialValues={{
          locationId: extraData?.locationId,
          theme: extraData?.theme ?? 'mijnalfa',
          safeEmailAddresses: extraData?.safeEmailAddresses,
        }}
        form={form}
        name="extra"
        labelCol={{ span: 12 }}
        onFinish={goNext}
      >
        <Form.Item
          label={t('labels.location')}
          name="locationId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            {locations.slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((location) => (
                <Select.Option key={location.id} value={location.id}>
                  {location.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('labels.theme')} name="theme">
          <Select>
            <Select.Option key="agrinota" value="agrinota">
              Agrinota
            </Select.Option>
            <Select.Option key="mijnalfa" value="mijnalfa">
              MijnAlfa
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('labels.mailbox')}
          name="mailbox"
          shouldUpdate
          className="text-left"
          initialValue={identifier}
        >
          <MailboxInput />
        </Form.Item>
        <Form.List name="safeEmailAddresses">
          {(fields, { add, remove }, { errors }) => (
            <>
              <Accent type="div" color="default">
                {t('labels.safeMailAddress')}
              </Accent>
              {fields.map((field, index) => (
                <>
                  <Form.Item {...field}>
                    <Input
                      type="email"
                      autoComplete="new-email"
                      addonAfter={
                        <FontAwesomeIcon
                          onClick={() => remove(index)}
                          icon={['far', 'trash-alt']}
                          size="sm"
                        />
                      }
                    />
                  </Form.Item>
                </>
              ))}
              <CustomButton onClick={() => add()}>
                {t('actions.addSafeMailAddress')}
              </CustomButton>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Row justify="space-between" style={{ marginTop: '2rem' }}>
            <CustomButton
              color="secondary"
              ghost
              onClick={() => setCurrentPage?.((prev) => prev - 1)}
            >
              {t('actions.back')}
            </CustomButton>
            <CustomButton type="primary" color="secondary" htmlType="submit">
              {t('actions.continue')}
            </CustomButton>
          </Row>
        </Form.Item>
      </Form>
    </Spin>
  );
};
