import { addRelationFavorite, deleteRelationFavorite } from '@/lib/adapters/users-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { Heading } from '@/lib/components/core/typography/heading';
import { RelationsTable } from '@/lib/components/relations/relationsTable';
import { useCurrentUserContext, useRelationContext } from '@/lib/context';
import { useGetLocationRelations } from '@/lib/queries';
import { SortOrder } from '@/lib/types';
import { RelationType } from '@/lib/types/enums';
import { Relation, RelationFilter } from '@/lib/types/user';
import { StarFilled } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import { useAtomValue } from 'jotai/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const RelationSelection: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { setRelationId, relation, isLoading } = useRelationContext();
  const hasAccess = useAtomValue(hasAccessAtom);
  const navigate = useNavigate();

  const setSelectedRelation = (relation: Relation) => {
    setRelationId(relation.id);
  };

  useEffect(() => {
    if (relation) {
      navigate('/');
    }
  }, [relation]);

  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          backgroundColor: 'white',
        }}
      >
        <Row
          justify="space-between"
          style={{ paddingTop: '30px', margin: '0 2rem' }}
          align="bottom"
        >
          <Col>
            <Heading text={t('general.actions.selectRelation')} />
          </Col>
        </Row>
        <Row>
          <Col flex={1} style={{ margin: '0 2rem' }}>
            {hasAccess.isCustomer ? (
              <CustomerRelationSelection
                onRelationSelect={setSelectedRelation}
              />
            ) : (
              <NonCustomerRelationSelection
                onRelationSelect={setSelectedRelation}
              />
            )}
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

interface RelationSelectionProps {
  onRelationSelect: (relation: Relation) => void;
}

const CustomerRelationSelection: React.FunctionComponent<
  RelationSelectionProps
> = ({ onRelationSelect }) => {
  const user = useCurrentUserContext();
  return (
    <RelationsTable
      relations={
        user?.relations.sort((x) => (x.id === user.relationId ? -1 : 1)) || []
      }
      total={user?.relations.length || 0}
      onRowClick={onRelationSelect}
      isLoading={false}
      renderIcons={(relation) =>
        relation.id === user?.relationId ? <StarFilled /> : null
      }
      columnSettings={{
        filterMultiple: false,
      }}
      tableSettings={{
        page: 1,
        pageSize: 10,
      }}
      setTableSettings={() => { }}
    />
  );
};

const NonCustomerRelationSelection: React.FunctionComponent<
  RelationSelectionProps
> = ({ onRelationSelect }) => {
  const [tableSettings, setTableSettings] = useState<RelationFilter>({
    page: 1,
    pageSize: 10,
    order: SortOrder.Descending,
    exactMatch: false,
    type: RelationType.Customer,
  });

  const { data: relations, refetch, isFetching } =
    useGetLocationRelations(tableSettings);

  const handleFavorite = async (relation: Relation) => {
    relation.isFavorite ? await deleteRelationFavorite(relation.id) : await addRelationFavorite(relation.id)
    refetch();
  }

  return (
    <RelationsTable
      isLoading={isFetching}
      relations={relations.data}
      tableSettings={tableSettings}
      setTableSettings={setTableSettings}
      total={relations?.total}
      onRowClick={onRelationSelect}
      handleFavorite={handleFavorite}
    />
  );
};
