import { FC, useEffect, useState } from 'react';
import { CustomButton } from '@/lib/components';
import { useTranslation } from 'react-i18next';
import { showNotification } from '@/lib/utils/showNotification';

type ImageViewProps = {
  url: string;
  onErrorComponent: React.ReactNode;
};

export const ImageView: FC<ImageViewProps> = ({ url, onErrorComponent }) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const downloadJson = async () => {
    try {
      // Create a link element and trigger the download
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const linkItems = url.split('?')[0].split('/');
      link.download = linkItems.at(-1) ?? 'factuur.png';
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

    } catch {
      showNotification('error', 'Fout tijdens ophalen factuur');
    }
  }
  useEffect(() => {
    if (url) {
      fetch(url)
        .then(() => {
          setIsError(false);
        })
        .catch((e) => {
          setIsError(true);
        });
    } else {
      setIsError(true);
    }
  }, [url]);

  return (
    <>{isError ? onErrorComponent :
      <>
        <div style={{ width: '100%', alignItems: 'center' }}>
          <CustomButton type="primary" color="secondary" onClick={downloadJson}>
            {t('general.actions.download')}
          </CustomButton>
        </div>
        <img src={url} alt="Document preview" onError={() => setIsError(true)} />
      </>
    }
    </>
  );
};
