import { getLabelValues } from '@/lib/adapters/label-adapter';
import { Label } from '@/lib/types/labels';
import { useQuery } from '@tanstack/react-query';
import { Form, FormItemProps, Select, Spin } from 'antd';

interface IDynamicLabelForm {
  label: Label;
}
const DEFAULT_LAYOUT: FormItemProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  style: { marginBottom: '2px' },
};
export const DynamicLabelForm = ({ label }: IDynamicLabelForm) => {
  const { isLoading, data } = useQuery({
    queryKey: ['labelValues', label.id],
    queryFn: () => getLabelValues(label.id),
    retry: false,
  });

  return (
    <Spin spinning={isLoading}>
      {data && (
        <Form.Item
          key={label.id}
          name={['labels', label.id]}
          label={label.name}
          {...DEFAULT_LAYOUT}
        >
          <Select
            options={
              data
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => ({
                  label: x.name,
                  value: x.id,
                }))}
          />
        </Form.Item>
      )}
    </Spin>
  );
};
