import {
  OtherDocument,
  OtherDocumentsFilter,
  PagedResponse,
} from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { createFilterParams } from '@/lib/utils/helpers';
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';
import { showNotification } from '@/lib/utils/showNotification';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { t } from 'i18next';

const BASE_URL = `${apiBasePaths.invoices}/v1/documents`;
const QUERY_KEY = ['documents'];

export const useGetDocuments = (
  filter?: OtherDocumentsFilter,
): UseQueryResult<PagedResponse<OtherDocument[]> | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, filter],
    queryFn: () =>
      api
        .get<PagedResponse<OtherDocument[]>>(`${BASE_URL}`, {
          params: createFilterParams(filter),
        })
        .then((r) => r.data),
  });

export const useDeleteDocument = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  return useMutation({
    mutationFn: (id: number) =>
      api.delete(`${BASE_URL}/${id}`).then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
    onError: (error: AxiosError) => {
      if (error.status == 403) {
        showNotification("error", t('general.errors.forbidden'));
      }
    }
  });
};

type DownloadDocumentParams = {
  id: number;
  filename: string;
};

export const useDownloadDocument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: DownloadDocumentParams) =>
      api
        .post(
          `${BASE_URL}/download`, { id }, { responseType: 'blob' }
        )
        .then((r) => r.data),
    onSuccess: (file, variables) => {
      if (file.size === 0) {
        showNotification("error", t('general.errors.unknown'));
        return;
      }
      var filename = variables.filename ?? 'onbekend_bestand'
      triggerFileDownload(file, filename, 'application/octet-stream');
    },
  });
};

export const useBatchDownloadDocuments = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids: number[]) =>
      api
        .post(
          `${BASE_URL}/batch/download`,
          ids.map((x) => ({ id: x })),
          { responseType: 'blob' }
        )
        .then((r) => r.data),
    onSuccess: (file) => {
      triggerFileDownload(file, 'documenten.zip', 'application/zip');
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
  });
};

function triggerFileDownload(data: any, fileName: string, fileType: string) {
  const url = window.URL.createObjectURL(new Blob([data], { type: fileType }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export const useBatchDeleteDocuments = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids: number[]) =>
      api
        .post(
          `${BASE_URL}/batch/delete`,
          ids.map((x) => ({ id: x })),
        )
        .then((r) => r.data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
    },
  });
};
